import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import { EXECUTIONS } from "../../../constants/applicationConstants";
import { DataTableHoc, Loader, ErrorFallback } from "smart-react";
import { GridColumnsList } from "../Components/Filter/FilterElements";
import {
  TabStrip,
  TabStripTab,
  Card,
  CardBody,
} from "@progress/kendo-react-layout";
import DataColumns from "../Components/DataColumns/DataColumns";
import {
  ROW_LAYOUTS,
  PAGE_SIZE,
} from "../../../constants/applicationConstants";
import {
  deleteFilter,
  applyFilter,
  saveFilters,
} from "../../../Utils/Filters/filterSliderHandler";
import ExecutionAnalytics from "./ExecutionAnalytics";
const BaseExecutionDataTable = React.lazy(
  () => import("../Components/DataTable/DataTable")
);
const ExecutionDataTable = DataTableHoc(BaseExecutionDataTable);

/**
 * Executions main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Executions component.
 */
const Executions = () => {
  const [userData, setUserData] = React.useState([]);
  const dataColumnsArray = DataColumns();
  // used to check API is ready to call
  const [isReady, setIsReady] = React.useState(false);
  const [selected, setSelected] = React.useState(0);
  const handleSelect = (e) => {
    setSelected(e.selected);
  };
  const navigate = useNavigate();
  /**
   * navigate to edit screen
   * @param {*} event
   */
  const editScreen = (dataItem) => {
    navigate(`/execution`, {
      state: {
        edit: true,
        dataSet: dataItem,
      },
    });
  };
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className="container mx-auto">
        <React.Suspense fallback={<Loader />}>
          <Card>
            <TabStrip
              className="page-tabs"
              selected={selected}
              onSelect={handleSelect}
            >
              <TabStripTab title="Result" contentClassName={`tabs-card`}>
                <Card className={`tabs-card`}>
                  <CardBody>
                    <div className="card-body">
                      <ExecutionDataTable
                        deleteFilter={deleteFilter}
                        applyFilter={applyFilter}
                        saveFilters={saveFilters}
                        moduleName={EXECUTIONS}
                        dataColumns={dataColumnsArray}
                        gridColumnsList={() => GridColumnsList({ editScreen })}
                        dataTableName={EXECUTIONS}
                        setIsReady={setIsReady}
                        isReady={isReady}
                        rowLayoutConfigName={`${EXECUTIONS}.DataGrid.RowLayout`}
                        pageLengthConfigName={`${EXECUTIONS}.DataGrid.ItemsPerPage`}
                        dataSet={userData}
                        setDataSet={setUserData}
                        initialSort={{
                          field: "id",
                          dir: "desc",
                        }}
                        isCardonMobileView={false}
                        total={process.env.DefaultLimit}
                        defaultRowLayout={process.env.RowLayout}
                        rowLayouts={ROW_LAYOUTS}
                        pageSizes={PAGE_SIZE}
                      />
                    </div>
                  </CardBody>
                </Card>
              </TabStripTab>
              <TabStripTab title="Analytics" contentClassName={`tabs-card`}>
                <Card className={`tabs-card`}>
                  <CardBody>
                    <ExecutionAnalytics />
                  </CardBody>
                </Card>
              </TabStripTab>
            </TabStrip>
          </Card>
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default Executions;
