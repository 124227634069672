import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Loader, ErrorFallback } from "smart-react";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
  ChartLegend,
  ChartTooltip,
  ChartSeriesItemTooltip,
} from "@progress/kendo-react-charts";
import "hammerjs";
import { getExecutionAnalytics } from "../Services/ExecutionService";
import "./ExecutionAnalytics.scss";

/**
 * ExecutionAnalytics main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The ExecutionAnalytics component.
 */
const ExecutionAnalytics = () => {
  const [loading, setLoading] = React.useState(false);
  const [dates, setDates] = React.useState({
    startDate: "",
    endDate: "",
  });

  const [reportData, setReportData] = React.useState({
    testCaseStatus: [],
    testCaseValidationStatus: [],
    testCasePlatforms: [],
    testCaseStatusByDates: [],
  });

  /**
   * handle Change
   * @param {e} Object
   * @param {field} string
   */
  const handleChange = (e, field) => {
    setDates({ ...dates, [field]: e.value });
  };

  /**
   * handle Search
   */
  const handleSearch = () => {
    getSummeryData();
  };
  /**
   * labelContentTC
   * @param {props} Object
   */
  const labelContentTC = (props) => {
    const total = reportData?.testCaseStatus?.reduce(
      (sum, item) => sum + item.Count,
      0
    );
    const percentage = (props.dataItem.Count / total) * 100;
    return `${props.dataItem.Status} : ${percentage.toFixed(2)}%`;
  };
  /**
   * labelContentTCV
   * @param {props} Object
   */
  const labelContentTCV = (props) => {
    const total = reportData?.testCaseValidationStatus?.reduce(
      (sum, item) => sum + item.Count,
      0
    );
    const percentage = (props.dataItem.Count / total) * 100;
    return `${props.dataItem.Status} : ${percentage.toFixed(2)}%`;
  };
  /**
   * getSummeryData
   */
  const getSummeryData = async () => {
    setLoading(true);
    const response = await getExecutionAnalytics({
      moduleName: "StatusSummary",
      isServiceWorker: false,
      data: dates,
    });
    setReportData(response?.analyticsData);
    setLoading(false);
  };

  React.useEffect(() => {
    getSummeryData();
  }, []);
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <React.Suspense fallback={<Loader />}>
        {loading && <Loader />}
        <div className="execution-analytics">
          <div className="date-form">
            <div className="k-px-2">
              <label>Start Date:</label>
              <DatePicker
                value={dates.startDate}
                format={"dd-MMM-yyyy"}
                onChange={(e) => handleChange(e, "startDate")}
              />
            </div>
            <div className="k-px-2">
              <label>End Date:</label>
              <DatePicker
                value={dates.endDate}
                format={"dd-MMM-yyyy"}
                onChange={(e) => handleChange(e, "endDate")}
              />
            </div>
            <div className="k-align-items-end k-d-flex k-px-2">
              <Button
                themeColor={"primary"}
                className="k-mx-1"
                onClick={handleSearch}
              >
                Search
              </Button>
            </div>
          </div>
          <div className="k-mt-5 test-cases-result-row">
            <div className="test-cases-result">
              <Chart>
                <ChartTitle text="Test Cases Status" />
                <ChartLegend position="bottom" />
                <ChartTooltip />
                <ChartSeries>
                  <ChartSeriesItem
                    type="pie"
                    data={reportData?.testCaseStatus}
                    field="Count"
                    categoryField="Status"
                    labels={{
                      visible: true,
                      content: labelContentTC,
                    }}
                  />
                </ChartSeries>
              </Chart>
            </div>
            <div className="test-cases-result">
              <Chart>
                <ChartTitle text="Test Cases Validation Status" />
                <ChartLegend position="bottom" />
                <ChartTooltip />
                <ChartSeries>
                  <ChartSeriesItem
                    type="pie"
                    data={reportData?.testCaseValidationStatus}
                    field="Count"
                    categoryField="Status"
                    labels={{
                      visible: true,
                      content: labelContentTCV,
                    }}
                  />
                </ChartSeries>
              </Chart>
            </div>
          </div>
          <div className="k-d-flex">
            <div className="test-cases-plaform">
              <Chart>
                <ChartTitle text="Test Cases Status by Platform" />
                <ChartLegend position="bottom" />
                <ChartCategoryAxis>
                  <ChartCategoryAxisItem
                    categories={reportData?.testCasePlatforms.map(
                      (item) => item.Platform
                    )}
                  />
                </ChartCategoryAxis>
                <ChartSeries>
                  <ChartSeriesItem
                    type="bar"
                    data={reportData?.testCasePlatforms.map(
                      (item) => item.Success
                    )}
                    name="Success"
                  />
                  <ChartSeriesItem
                    type="bar"
                    data={reportData?.testCasePlatforms.map(
                      (item) => item.Failure
                    )}
                    name="Failure"
                  />
                </ChartSeries>
                <ChartTooltip
                  render={({ point }) => `${point.category}: ${point.value}`}
                />
              </Chart>
            </div>
          </div>
          <div className="k-d-flex">
            <div className="test-cases-plaform">
              <Chart
                pannable={{
                  lock: "y",
                }}
                zoomable={{
                  mousewheel: {
                    lock: "y",
                  },
                }}
              >
                <ChartTitle text="Test Cases By Dates" />
                <ChartLegend position="bottom" />
                <ChartTooltip />
                <ChartCategoryAxis>
                  <ChartCategoryAxisItem
                    title={{
                      text: "Dates",
                    }}
                    categories={reportData?.testCaseStatusByDates.map(
                      (item) => item.Date
                    )}
                    max={20}
                  />
                </ChartCategoryAxis>
                <ChartSeries>
                  <ChartSeriesItem
                    type="line"
                    data={reportData?.testCaseStatusByDates.map(
                      (item) => item.Success
                    )}
                  >
                    <ChartSeriesItemTooltip
                      render={({ point }) => (
                        <span>
                          <b>Success : {point.value}</b>
                        </span>
                      )}
                    />
                  </ChartSeriesItem>
                  <ChartSeriesItem
                    type="line"
                    data={reportData?.testCaseStatusByDates.map(
                      (item) => item.Failure
                    )}
                  >
                    <ChartSeriesItemTooltip
                      render={({ point }) => (
                        <span>
                          <b>Failure : {point.value}</b>
                        </span>
                      )}
                    />
                  </ChartSeriesItem>
                </ChartSeries>
              </Chart>
            </div>
          </div>
        </div>
      </React.Suspense>
    </ErrorBoundary>
  );
};
export default ExecutionAnalytics;
